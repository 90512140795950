/* Footer Styling */
.footer {
  background-color: #f4f4f4;
  padding: 1rem 0;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

/* Additional Styling (Adjust as needed) */
.footer-section {
  flex: 1;
}

/* Styling for social media icons */
.footer-section div {
  margin-top: 0px;
}

.footer-section .MuiIconButton-root {
  margin: 0 5px;
  padding: 5px;
}

.footer-section a {
  color: #334986;
}
