/* Hero Section Styling */
.hero-section {
  position: relative;
  height: 50vh;
  overflow: hidden;
}

/* Background Image Styling */
.hero-image {
  background-image: url("../../images/bg_hero_2.jpg"); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Text Overlay Styling */
.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white; /* Adjust text color based on your background image */
  width: 100%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

/* Additional Styling (Adjust as needed) */
.hero-text h1 {
  font-size: 3em;
  margin-bottom: 10px;
  /* font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif; */
}

.hero-text p {
  font-size: 1.2em;
}

@media (max-width: 640px) {
  .hero-text h1 {
    font-size: 2.5em;
  }
}
