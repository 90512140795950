/* Schedule Consultation Styling */
.schedule-consultation-container {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.schedule-consultation-paper {
  padding: 20px;
  max-width: 800px;
  width: 100%;
}

/* Additional Styling (Adjust as needed) */
form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Styling for form elements */
.MuiFormControl-root {
  width: 100%;
}
