/* Services List Styling */
/* .services-list {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 1rem;
} */

/* Service Card Styling */
.service-card {
  width: 320px;

  transition: transform 0.3s;
  margin: 1rem;
}

.service-card:hover {
  transform: scale(1.05);
}
